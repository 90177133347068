<template>
  <div class="bg-mafia-dark flex-column position-relative">
    <div class="popup" v-if="!isConfirmSms">
      <SMS_POPUP
          @request="postRequest"
          @confirm="ok"
          @cancel="closePopup"
      ></SMS_POPUP>
    </div>
  </div>
</template>


<script>

import SMS_POPUP from "@/view/Auth/smsPopup";

export default {
  name: 'pin_sms1'
  , components: {SMS_POPUP}
  , data: function(){
    return {
      program: {
        name: 'sms'
        , title: 'PIN 번호 분실 복구'
        , not_header: true
        , not_footer: true
        , type: 'auth'
      }
      , is_pin: false
      ,pin_option: {
        pin_type: 'lost'
        , is_can_cancel: true
      }
      , item: {
        mphone_number: ''
        , certfc_request_key: ''
        , certfc_number: ''
      }
      , nickname: ''
      , member_number: ''
      , isConfirmSms: false  // SMS 인증이 정상적으로 완료되었는가?
    }
  }
  ,  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        /* router path가 변경될 때마다 서버로 접근로그를 저장한다. */
        this.$log.console('path 바뀜!!')
        this.onPinPopup()
      }
    }
  }
  , computed: {
    is_send: function(){
      let t = true
      if(!this.item.certfc_request_key && this.item.mphone_number.length == 11){
        t = false
      }

      return t
    }
    , is_number: function(){
      let t = true
      if(this.item.certfc_request_key == ''){
        t = false
      }
      return t
    }
    , is_code: function(){
      let t = true
      if(this.item.certfc_request_key && this.item.mphone_number.length == 11){
        t = false
      }
      return t
    }
  }
  , methods: {
    postOtp: async function(){
      this.$log.console('postOtp')
      try{
        this.$bus.$emit('on', true)
        if(!this.certfc_number){
          throw 'OTP 번호를 입력하세요'
        }
        const result = await this.$Request({
          method: 'post'
          , url: this.$api_url.api_path.post_google_otp
          , data: {
            certfc_number: this.certfc_number
          }
          , type: true
        })

        if(result.success){
          this.is_pin = true
        }else{
          throw result.message
        }

      }catch (e) {
        this.$log.console(e)
        this.$bus.$emit('notify',  { type: 'error', message: e})
      }finally {
        this.$bus.$emit('on', false)
      }
    }
    ,pinClick: async function(pin, pin_confirm){
      this.$log.console(pin, pin_confirm)
      this.pinnumber = pin
      this.confirmation_pinnumber = pin_confirm
      await this.postConfirm()
    }
    ,pinCancel: function(){
      this.$log.console('cancel')
      this.is_pin = false
      this.pin_option.pin_type = 'lost'
    }
    , getGoogleOtpKey: async function(){
      this.$log.console('getGoogleOtpKey')
      try{
        this.$bus.$emit('on', true)
        const result = await this.$Request({
          method: 'post'
          , url: this.$api_url.api_path.get_google_otp_key
          , data: {
          }
          , type: true
        })

        if(result.success){
          this.$log.console(result.data)
          this.item.mphone_number = result.data.mphone_number
          this.item.nickname = result.data.nickname
          this.item.member_number = result.data.member_number
        }else{
          throw result.message
        }

      }catch (e) {
        this.$log.console(e)
        this.$bus.$emit('notify',  { type: 'error', message: e})
        this.$bus.$emit('to', {name: 'join'})
      }finally {
        this.$bus.$emit('on', false)
      }
    }
    ,clipBoard: function (val){
      const t = document.createElement("textarea");
      document.body.appendChild(t);

      t.value = val;
      this.$log.console('t.value', t.value)
      t.select();
      t.setSelectionRange(0, 9999)
      document.execCommand('copy');
      document.body.removeChild(t);
    }
    , postRequest: async function  (phoneNumber){
      try{
        this.$bus.$emit('on', true)
        this.item.mphone_number = phoneNumber
        const result = await this.$Request({
          method: 'post'
          , url: this.$api_url.api_path.post_pin_lost
          , data: {
            //mphone_number: this.item.mphone_number
            mphone_number: phoneNumber
          }
          , type: true
        })

        if(result.success){
          this.$log.console(result.data)
          this.item.certfc_request_key = result.data.certfc_request_key
        }else{
          throw result.message
        }

      }catch (e) {
        this.$log.console(e)
        this.$bus.$emit('notify',  { type: 'error', message: e})
      }finally {
        this.$bus.$emit('on', false)
      }
    }

    , closePopup() {
      this.$emit('offPopup')
      this.isConfirmSms = true
    }

    , ok: function (verifyCode = '') {
      this.item.certfc_number = verifyCode
      this.closePopup()
      this.$emit('changePinType', this.item)
    }
    , postConfirm: async function  (verifyCode = ''){
      try{
        this.$bus.$emit('on', true)
        const result = await this.$Request({
          method: 'post'
          , url: this.$api_url.api_path.post_pin_befor_login
          , data: {
            mphone_number: this.item.mphone_number
            , certfc_request_key: this.item.certfc_request_key
            , certfc_number: verifyCode
            , pinnumber: this.pinnumber
            //, confirmation_pinnumber: this.confirmation_pinnumber
            , confirmation_pinnumber: verifyCode
          }
          , type: true
        })

        if(result.success){

			if(result.data.session_token){
				await this.$encodeStorage.setSessionToken(result.data.session_token)
			}
          await this.$encodeStorage.setSessionAt(result.data)

          this.$bus.$emit('setUser', result.data)
          await this.$router.push({ name: 'main'})
        }else{
          throw result.message
        }

      }catch (e) {
        this.$log.console(e)
        this.onPin()
        this.$bus.$emit('notify',  { type: 'error', message: e})
      }finally {
        this.$bus.$emit('on', false)
      }
    }
    , onPin: function(){
      if(this.is_pin){
        this.is_pin = false
        setTimeout( () => {
          this.is_pin = true
        }, 5)
      }else{
        this.is_pin = true
      }
    },

    onPinPopup() {
      this.$bus.$emit('onPin', 'pin_login')
      this.$emit('onPopup')
      this.isConfirmSms = false
    }
  }
  , created() {
    this.$bus.$emit('onLoad', this.program)
    this.onPinPopup()
  }
}
</script>
<style>
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 700px;
  box-shadow: 7px 7px 5px rgba(0, 0, 0, 0.2);
  z-index: 100000;
}
</style>